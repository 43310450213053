import PropTypes from 'prop-types'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '../graphql/client'
import { setGlobal } from 'reactn'
import CssBaseline from '@mui/material/CssBaseline'
import 'react-quill/dist/quill.snow.css'
import { useEffect, useMemo, useState } from 'react'
import { clientWindow, getVersion } from '../src/helpers/helper'
import '../src/styles/globalStyles.css'
import '../src/styles/colorPicker.module.css'
import { LicenseInfo } from '@mui/x-data-grid-premium'
import { SessionProvider } from 'next-auth/react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { datadogRum } from '@datadog/browser-rum'
import { useRouter } from 'next/router'
import { lightTheme, darkTheme } from '../src/theme'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

datadogRum.init({
  applicationId: '3d9757fe-4a55-435a-a4f6-071dabd7415b',
  clientToken: 'pubf61202cf496aeda880d5a7207887eedf',
  site: 'datadoghq.com',
  service: 'caseopp',
  env: process.env.NODE_ENV || 'development',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: getVersion(),
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  enableExperimentalFeatures: ['clickmap'],
  trackResources: true,
  trackLongTasks: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'allow'
})

LicenseInfo.setLicenseKey(process.env.MUIX_LICENSE_KEY)

export default function CaseOppApp({ Component, pageProps: { session, ...pageProps }, err }) {
  const apolloClient = useApollo(pageProps)
  const router = useRouter()
  const [themeMode, setThemeMode] = useState('light')

  const theme = useMemo(() => {
    return themeMode === 'light' ? lightTheme : darkTheme
  }, [themeMode])

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
    setGlobal({
      isLoading: false,
      loadingMessage: 'Loading Page',
      loadingStack: [],
      loggedIn: false,
      loggedInUser: null
    })
  }, [])

  // get theme mode from local storage
  useEffect(() => {
    const themePref = clientWindow.localStorage.getItem('themeMode')
    if (themePref) {
      setThemeMode(themePref)
    }
  }, [])

  let favicon = '/favicon.png'
  if (clientWindow) {
    if (
      clientWindow.location.href.indexOf('sta.caseopp.com') > -1 ||
      clientWindow.location.href.indexOf('sta.rdevs.com') > -1
    ) {
      favicon = '/favicon-sta.png'
    } else if (window.location.href.indexOf('localhost:3000') > -1 || window.location.href.indexOf('vercel.app') > -1) {
      favicon = '/favicon-local.png'
    }
  }

  return (
    <SessionProvider session={session}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ApolloProvider client={apolloClient}>
              <Head>
                <title>CaseOpp</title>
                <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
                <link rel='icon' type='image/x-icon' href={favicon} />
              </Head>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Component router={router} {...pageProps} err={err} />
            </ApolloProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </SessionProvider>
  )
}
CaseOppApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object
}
